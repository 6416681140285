@charset "utf-8";

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

@font-face {
	font-family: "CollateralDamage";
	src: url("fonts/2B9200_0_0.eot");
	src: url("fonts/2B9200_0_0.eot?#iefix") format("embedded-opentype"),
		url("fonts/2B9200_0_0.woff") format("woff"), url("fonts/2B9200_0_0.ttf") format("truetype");
}

@font-face {
	font-family: "source_sans_proregular";
	src: url("fonts/sourcesanspro-regular-webfont.eot");
	src: url("fonts/sourcesanspro-regular-webfont.eot?#iefix") format("embedded-opentype"),
		url("fonts/sourcesanspro-regular-webfont.woff") format("woff"),
		url("fonts/sourcesanspro-regular-webfont.ttf") format("truetype"),
		url("fonts/sourcesanspro-regular-webfont.svg#source_sans_proregular") format("svg");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "SpecialElite";
	src: url("fonts/SpecialElite.ttf");
	font-weight: normal;
	font-style: normal;
}
.utc-checkbox {
	float: right;
	width: 20%;
}
.nav li.mean-only {
	display: none;
}
.mean-only ul li {
	text-transform: lowercase;
}

em {
	font-style: italic;
}

h1 {
	font-family: "inherit";
	color: #670001;
	font-size: 74pt;
	line-height: 0.9em;
}
h2 {
	font-family: "Oxygen";
	font-weight: normal;
	color: #670001;
	margin: 0 auto 0.3em;
	max-width: 1000px;
	padding: 0 1em 0 0;
	font-size: 120%;
}
h3 {
	color: #670001;
	margin: 0 auto 0.5em;
	max-width: 1000px;
	/* padding: 0 1em; */
	font-weight: normal;
	font-style: italic;
}
table {
	margin-bottom: 20px;
	width: 100%;
}

input[type="checkbox"] {
	width: 3%;
	float: left;

}


input {
	margin: 0px 0px 5px 30px;
}

select {
	width: 30%;
	float: inline-start;
}

label {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	text-align: left;
	display: -webkit-box;
}

.PrescriberSearch{
	padding: 10px 10px 10px 30px;
	border: solid 1px black;
	margin-bottom: 1.5em;;
}

.submit {
	width: 50%;
	display: block;
	margin: 0 auto;
}
.submit:hover {
	background: #000000;
	color: #ffffff;
}

.wrapper-header{
	padding: 15px;
}

.clearfix {
	clear: both;
	float: none !important;
	height: auto !important;
	width: auto !important;
}

strong {
	font-weight: bold;
	color: #670001;
}

a:link,
a:visited,
a:active,
h2 a:link,
h2 a:visited,
h2 a:active {
	text-decoration: underline;
	color: #941704;
}
a:hover {
	text-decoration: underline;
	color: #000000;
}
html {
	background: url("https://www.unither.com/gfx/") fixed;
}
p {
	margin-bottom: 1.5em;
}
body {
	font-family: "source_sans_proregular", sans-serif;
	font-size: 12pt;
	color: #222222;
	font-weight: 300;
}

.top-bar {
	height: 15px;
	background: url("https://www.unither.com/gfx/horzbar-trans.png");
	position: relative;
	top: 8px;
	opacity: 0.5;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.top-bar img {
	height: 15px;
	width: 100%;
}

.page {
	margin: 0 auto;
	background: url("https://www.unither.com/gfx/page-back.jpg");
}

.header {
	margin: 0 auto;
	height: 183px;
	background: rgb(21, 0, 97);
	position: relative;
	/* background: url("https://www.unither.com/gfx/header-misc.jpg") center bottom; */
}
.logo {
	position: absolute;
	top: 0;
	left: 0;
	width: 70%;
	max-width: 388px;
	z-index: 999999;
	height: 100%;
}
.logo a {
	display: block;
	height: 100%;
}
.logo img {
	width: auto;
	height: auto;
}
.paper-top {
	background-image: url("https://www.unither.com/gfx/paper-top.png");
	height: 52px;
	width: 100%;
	position: absolute;
	bottom: 0;
}
.nav {
	width: 100%;
	height: 31px;
	font-size: 11pt;
	color: #e2212b;
	background-color: #2e271d;
	font-family: "Oxygen";
	text-transform: uppercase;
	font-weight: 600;
	padding-top: 52px;
}
.nav-bar {
	width: 100%;
	position: absolute;
	height: 83px;
	background-color: #2e271d;
}

.nav a {
	text-decoration: none;
	color: #cccccc;
}
.nav a:hover {
	color: #ffffff;
}
.nav ul {
	/* width: 100%; */
	list-style-type: none;
	/* position: relative; */
	/* bottom: 0; */
	position: relative;
	margin-bottom: 0;
	float: right;
}
.nav li {
	display: inline;
	float: left;
	padding-right: 40px;
	position: relative;
	padding-bottom: 13px;
}

.nav li ul {
	display: none;
	position: absolute;
	float: none;
	background: #000000;
	top: 20px;
	left: -15px;
	padding-left: 0px;
	width: 170px;
	z-index: 5;
	margin-top: 11px;
}
.nav li:hover ul {
	display: block;
}
.nav li ul li {
	display: block;
	float: none;
	padding: 10px 15px 10px;
	width: 140px;
	text-transform: none;
}
.nav li ul li:hover {
	background-color: #aaaaaa;
}
.nav li ul li a:hover,
.nav li ul li:hover a {
	color: #000000;
}
.body {
	margin: 20px auto;
	max-width: 1000px;
	padding-bottom: 1em;
}

.paper-bottom {
	background-image: url("https://www.unither.com/gfx/paper-bottom2.png");
	height: 130px;
	width: 100%;
	position: absolute;
	top: 0;
}
.footer {
	clear: both;
	height: 300px;
	background: rgb(21, 0, 97);
	text-align: center;
	/* background: url("https://www.unither.com/gfx/footer-misc.jpg") center bottom; */
	position: relative;
}
.footer-logo {
	width: 300px;
	height: 46px;
	background: url("https://www.unither.com/gfx/logo-white.png");
	margin: 20px 40px 0 20px;
	float: left;
	position: relative;
}
.footer .top-bar {
	position: relative;
	top: -15px;
	z-index: -2;
}
.footer ul {
	list-style: none;
	width: 80%;
	margin-left: 20%;
	position: relative;
	z-index: 2;
}
.footer li {
	float: left;
	margin-right: 2em;
	font-size: 80%;
	color: #ffffff;
	font-family: "Oxygen";
	font-weight: 700;
	font-size: 11pt;
	margin-top: 28px;
}
.footer li.footer-last {
	float: right;
}
.footer li a {
	color: #ffffff;
	font-family: "Oxygen";
	font-weight: 700;
	font-size: 11pt;
}
.footer li a:link,
a:visited,
a:active {
	text-decoration: none;
}
.footer li a:hover {
	text-decoration: underline;
}
.footer p {
	padding: 0;
	display: inline;
}
.footer-social {
	position: absolute;
	bottom: 0;
	margin-bottom: 20px;
	left: 50%;
	color: white;
	font-weight: bold;
	font-size: large;
}

.fieldset{
	display: grid;
}

.section-header {
	width: 100%;
	background-repeat: no-repeat;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	background-position: center;
	display: table;
	margin-bottom: 40px;
}
.section-header img {
	width: 40%;
	height: auto;
}

.section-content {
	margin: 0 auto;
	color: #444444;
	font-size: 1em;
	padding: 0;
	display: table;
	width: 100%;
	min-height: 300px;
}

.section-content p {
	text-align: left;
	max-width: 1000px;
	margin: 0 auto 1em;
	padding: 0 1em;
}
.section-content a {
	color: #941704;
}
.section-content a:hover {
	color: #000000;
}
.section-content ul {
	list-style: none;
	margin: 0 auto;
	max-width: 800px;
	font-size: 90%;
}
.section-content li {
	margin-bottom: 1em;
}
.section-content ul ul {
	margin-left: 1em;
	margin-top: 15px;
	border-left: 1px solid #dddddd;
	padding-left: 20px;
}

.full-image {
	width: 100%;
	max-width: 1000px;
	display: block;
	height: auto;
	margin: 1em auto;
	clear: both;
}
.nat-image {
	width: auto;
}
.third-image {
	width: 33%;
	height: auto;
	margin: 1em;
}
.half-image {
	width: 50%;
	height: auto;
	margin: 1em;
}
.quarter-image {
	width: 25%;
	height: auto;
	margin: 1em;
}

.executives {
	clear: both;
	max-width: 1000px;
	margin: 0 auto;
	text-align: center;
}

.executives div {
	width: 21%;
	margin: 0 0.5em 1em 0.5em;
	display: inline-block;
	height: 14em;
}
.executives img {
	width: 100%;
	height: auto;
}
.executives p {
}

.product {
	padding: 1em 1em;
	clear: left;
	max-width: 1000px;
	margin: 0 auto;
}
.product div {
	display: table-cell;
	vertical-align: top;
	width: 70%;
}
.product div.product-logo {
	width: 30%;
}
.product-logo h2 {
	padding: 0;
}

.product div.top-bar {
	top: 0;
	width: 100%;
	margin-bottom: 1em;
	display: block;
}
.product h3 {
	font-size: 100%;
}
.product h3.pad {
	padding-top: 3em;
}

.product img {
	width: 100%;
	float: left;
	margin: 0 2em 1em 0;
}
.product h3 {
	font-style: normal;
	font-weight: 700;
	color: #670001;
}
.product h4 {
	font-style: italic;
	padding: 0 1em;
}
.product p {
	margin-left: 20px;
}
.product ul {
	margin-left: 40px;
}
.product p,
.product li {
	font-size: 80%;
}

.right {
	float: right;
	margin: 0 0 1em 1em;
}
.left {
	float: left;
	margin: 0 1em 1em 0;
}

.patients {
	margin-bottom: 50px;
}
.patients a:link,
.patients a:active,
.patients a:visited {
	text-decoration: none;
}
.patients a:hover {
	text-decoration: underline;
}
.patients div {
	display: table-cell;
	vertical-align: middle;
	width: 50%;
}
.patients img {
	width: 80%;
	margin-left: 15px;
}

.centered {
	text-align: center !important;
	margin: 0 auto !important;
}

.videos {
	clear: both;
	text-align: center;
	width: 100%;
}

.videos div {
	width: 30%;
	margin: 0 10px 10px;
	padding: 0;
	display: inline-block;
}
.videos img {
	width: 100%;
	opacity: 0.7;
}
.videos div:hover img {
	opacity: 1;
}

.togglemultibutton {
	width: 23%;
	display: inline-block;
	margin: 0;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
	opacity: 0.9;
	position: relative;
}
.togglemultibutton:hover {
	opacity: 1;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	text-decoration: underline;
}

.togglemultibutton img {
	width: 100%;
}
.togglemulti {
	display: none;
	margin-top: 20px;
}
.togglemulti img {
	width: 920px;
	margin-bottom: 20px;
}
.togglemulticlose-button {
	margin-top: 50px !important;
	text-align: center !important;
}
.location-name {
	font-family: "SpecialElite";
	text-align: center;
	color: #000000;
	padding: 7px;
	font-size: 16pt;
}
.thumbs {
	text-align: center;
}
.thumbs img {
	display: inline-block;
	width: 32%;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
	opacity: 0.8;
}
.thumbs img:hover {
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
	opacity: 1;
}
ul.sitemap {
	border: none !important;
	margin-left: 20px;
}
.sitemap a {
	color: #670001;
	text-decoration: none;
}

.sitemap a:hover {
	color: #670001;
	text-decoration: underline;
}
/* 
.header-products {
	background-image: url("https://www.unither.com/gfx/header-products.jpg");
}
.footer-products {
	background-image: url("https://www.unither.com/gfx/footer-products.jpg");
}
.header-careers {
	background-image: url("https://www.unither.com/gfx/header-company2.jpg");
}
.footer-careers {
	background-image: url("https://www.unither.com/gfx/footer-company2.jpg");
}
.header-company {
	background-image: url("https://www.unither.com/gfx/header-company-randy.jpg");
}
.footer-company {
	background-image: url("https://www.unither.com/gfx/footer-company-randy.jpg");
}
.header-pipeline {
	background-image: url("https://www.unither.com/gfx/header-careers2.jpg");
}
.footer-pipeline {
	background-image: url("https://www.unither.com/gfx/footer-careers2.jpg");
}
.header-patients {
	background-image: url("https://www.unither.com/gfx/header-patients.jpg");
}
.footer-patients {
	background-image: url("https://www.unither.com/gfx/footer-patients.jpg");
}
.header-investors {
	background-image: url("https://www.unither.com/gfx/header-investors.jpg");
}
.footer-investors {
	background-image: url("https://www.unither.com/gfx/footer-investors.jpg");
} */

.togglebox {
	display: none;
}
.toggle-button {
	color: #941704;
}

/* move elements for smaller screen */
@media screen and (max-width: 1400px) {
	.header-products {
		/* background-image: url("https://www.unither.com/gfx/header-products.jpg"); */
	}
	.footer li {
		float: none;
		display: inline-block;
		font-size: 10pt;
		margin-top: 15px;
	}
	.footer li.footer-last {
		float: none;
	}
	.footer ul {
		text-align: center;
	}
}
@media screen and (max-width: 1280px) {
	.desk-only {
		display: none;
	}

	.nat-image {
		max-width: 100%;
	}
	h2 img {
		max-width: 100%;
	}

	.section-content {
		display: block;
	}
	.nav li a {
		background-image: none !important;
		width: auto !important;
		height: auto !important;
	}
	.section-content p {
		text-align: left;
		font-size: 1em;
	}

	.footer-logo {
		display: none;
	}
	.footer ul {
		margin: 0 auto;
	}
	/* .footer {
		background-image: url("https://www.unither.com/gfx/footer-misc1280.jpg");
	}
	.header {
		background-image: url("https://www.unither.com/gfx/header-misc1280.jpg");
	}
	.footer-products {
		background-image: url("https://www.unither.com/gfx/footer-products1280.jpg");
	}
	.header-careers {
		background-image: url("https://www.unither.com/gfx/header-company21280.jpg");
	}
	.footer-careers {
		background-image: url("https://www.unither.com/gfx/footer-company21280.jpg");
	}
	.header-company {
		background-image: url("https://www.unither.com/gfx/header-company-randy1280.jpg");
	}
	.footer-company {
		background-image: url("https://www.unither.com/gfx/footer-company-randy1280.jpg");
	}
	.header-pipeline {
		background-image: url("https://www.unither.com/gfx/header-careers21280.jpg");
	}
	.footer-pipeline {
		background-image: url("https://www.unither.com/gfx/footer-careers21280.jpg");
	}
	.header-patients {
		background-image: url("https://www.unither.com/gfx/header-patients1280.jpg");
	}
	.footer-patients {
		background-image: url("https://www.unither.com/gfx/footer-patients1280.jpg");
	}
	.header-investors {
		background-image: url("https://www.unither.com/gfx/header-investors1280.jpg");
	}
	.footer-investors {
		background-image: url("https://www.unither.com/gfx/footer-investors1280.jpg");
	} */
}

@media screen and (max-width: 1100px) {
	h1 {
		padding-left: 20px;
		font-size: 50pt;
	}
	h2 {
		padding-left: 20px;
	}
} /*/mediaquery*/
@media screen and (max-width: 950px) {
	.footer ul {
		text-align: center;
	}
	.executives div {
		height: 10em;
	}
} /*/mediaquery*/
@media screen and (max-width: 730px) {
	.section-header .top-bar,
	.section-header .left-bar,
	.section-header .right-bar,
	.section-content .left-bar,
	.section-content .right-bar {
		display: none;
	}
	.executives div {
		width: 29%;
		margin: 0 10px 20px;
		display: inline-block;
		height: 12em;
	}
	h1 {
		font-size: 35pt;
		padding-left: 0;
	}
	.logo img {
		width: auto;
		height: 100%;
	}
	.videos div {
		width: 44%;
		margin: 0 5px 10px;
	}
	.product div.product-logo {
		width: 100%;
		text-align: center;
	}
	.product img {
		width: 50%;
		margin: 0 auto 20px;
		float: none;
	}
	.product div {
		display: block;
		float: none;
		width: auto;
	}
	/* .footer {
		background-image: url("https://www.unither.com/gfx/footer-misc720.jpg");
	}
	.header {
		background-image: url("https://www.unither.com/gfx/header-misc720.jpg");
		height: 100px;
	}
	.footer-products {
		background-image: url("https://www.unither.com/gfx/footer-products720.jpg");
	}
	.header-careers {
		background-image: url("https://www.unither.com/gfx/header-company2720.jpg");
	}
	.footer-careers {
		background-image: url("https://www.unither.com/gfx/footer-company2720.jpg");
	}
	.header-company {
		background-image: url("https://www.unither.com/gfx/header-company-randy720.jpg");
	}
	.footer-company {
		background-image: url("https://www.unither.com/gfx/footer-company-randy720.jpg");
	}
	.header-pipeline {
		background-image: url("https://www.unither.com/gfx/header-careers2720.jpg");
	}
	.footer-pipeline {
		background-image: url("https://www.unither.com/gfx/footer-careers2720.jpg");
	}
	.header-patients {
		background-image: url("https://www.unither.com/gfx/header-patients720.jpg");
	}
	.footer-patients {
		background-image: url("https://www.unither.com/gfx/footer-patients720.jpg");
	}
	.header-investors {
		background-image: url("https://www.unither.com/gfx/header-investors720.jpg");
	}
	.footer-investors {
		background-image: url("https://www.unither.com/gfx/footer-investors720.jpg");
	} */
	h1,
	h2,
	h3 {
		/* text-align: center; */
	}
} /*/mediaquery*/
@media screen and (max-width: 500px) {
	h1 {
		font-size: 28pt;
	}
	.submit {
		height: 100%;
	}
	.logo img {
		width: auto;
		height: 100%;
	}
	.executives div {
		width: 42%;
		margin: 0 10px 10px;
		display: inline-block;
		height: 11em;
	}
	.patients {
		text-align: center;
	}
	.patients div h2 {
		font-size: 100%;
	}
	.patients div {
		display: block;
		width: 100%;
	}
} /*/mediaquery*/
@media screen and (max-width: 325px) {
	.executives div {
		width: 42%;
		margin: 0 10px 10px;
		display: inline-block;
		height: 8em;
	}
	.logo {
		height: 100%;
	}
	.logo img {
		width: auto;
		height: 100%;
	}
	.header {
		height: 88px;
		background-image: none !important;
	}
} /*/mediaquery*/