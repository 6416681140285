/* 
Custom CSS for UNITHR pipeline
*/
/* external css: flickity.css */

/* external css: flickity.css */
/* Modal Header */
.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Body */
.modal-body {
  padding: 2px 16px;
}

/* Modal Footer */
.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0
  }

  to {
    top: 0;
    opacity: 1
  }
}

/* external css: flickity.css */

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

.carousel {
  background: #EEE;
}

.carousel img {
  display: block;
  height: 200px;
}

@media screen and (min-width: 768px) {
  .carousel img {
    height: 400px;
  }
}


.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.divTable {
  display: table;
  width: 100%;
}

/*.divTableRow {
display: table-row;
}*/
.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
}

.divTableCell,
.divTableHead {
  border: none;
  display: table-cell;
  padding: 0px;
}

.divTableHeading {
  background-color: #EEE;
  display: table-header-group;
  font-weight: bold;
}

.divTableFoot {
  background-color: #EEE;
  display: table-footer-group;
  font-weight: bold;
}

/*.divTableBody {
display: table-row-group;
}*/

.container {
  margin-left: 11px;
  margin-right: 11px;
}

/* Global Styles */

html,
body {
  height: 100%;
  font-family: Georgia, "Times New Roman", Times, serif;
}

hr.hr1 {
  color: #f00;
  background-color: #f00;
  height: 5px;
}

hr {
  display: block;
  -webkit-margin-before: 0.5em;
  -webkit-margin-after: 0.5em;
  -webkit-margin-start: auto;
  -webkit-margin-end: auto;
  border-style: inset;
  border-width: 1px;
  border-top: 3px solid #c90024;
  border-bottom: 2px dashed #000;
  color: #fff;
  background-color: #fff;
  height: 4px;
}

hr.hr4 {
  border-top: 1px dashed #c90024;
  border-bottom: 1px solid #000;
  color: #c90024;
  background-color: #c90024;
  height: 4px;
}

.dropdown {
  cursor: pointer;
}


.img-home-portfolio,
.img-customer,
.portfolio-item {
  margin-bottom: 30px;
}

.tab-pane {
  margin-top: 15px;
}

/* Page Sections */
.section,
.section-colored {
  padding: 50px 0;
}

.section-colored {
  background-color: #e1e1e1;
  /* change this to change the background color of a colored section */
}

.sidebar {
  margin-top: 40px;
}

.sidebar ul {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #cccccc;
}

/* Half Page Height Carousel Customization */
.carousel {
  height: 50%;
}

.item,
.active,
.carousel-inner {
  height: 100%;
}

.fill {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
}

/* Social Icons Styles */
.list-social-icons {
  margin-bottom: 45px;
}

.tooltip-social a {
  text-decoration: none;
  color: inherit;
}

.facebook-link a:hover {
  color: #3b5998;
}

.linkedin-link a:hover {
  color: #007fb1;
}

.twitter-link a:hover {
  color: #39a9e0;
}

.google-plus-link a:hover {
  color: #d14836;
}

/* Service Page Styles */
.service-icon {
  font-size: 50px;
}

/* 404 Page Styles */
.error-404 {
  font-size: 8em;
}

/* Pricing Page Styles */
.price {
  font-size: 4em;
}

.price-cents {
  vertical-align: super;
  font-size: 50%;
}

.price-month {
  font-size: 35%;
  font-style: italic;
}

/* Footer Styles */
footer {
  margin: 50px 0;
}

/* Responsive Styles */
@media (max-width: 767px) {

  .carousel {
    height: 70%;
    /* increases the carousel height so it looks good on phones */
  }
}


.prevBtn2019 {
  position: absolute;
  bottom: 0px;

  left: -30px;
  width: 100px;
  height: 30px;
}

.nextBtn2019 {
  bottom: 0px;
  position: absolute;

  left: 430px;
  width: 100px;
  height: 30px;
}

#img_container {
  position: relative;
  display: inline-block;
  text-align: center;
  border: none;
}

.prevBtn {
  position: absolute;
  top: -10px;

  left: -30px;
  width: 100px;
  height: 30px;
}

.nextBtn {
  top: -10px;
  position: absolute;

  left: 430px;
  width: 100px;
  height: 30px;
}
.table {
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  display: table;
}
@media screen and (max-width: 580px) {
  .table {
    display: block;
  }
}

.row {
  display: table-row;
  background: #f6f6f6;
}
.row:nth-of-type(odd) {
  background: #e9e9e9;
}
.row.rowheader {
  font-weight: 900;
  color: #ffffff;
  background:rgb(33 0 151);
}

@media screen and (max-width: 580px) {
  .row {
    padding: 14px 0 7px;
    display: block;
  }
  .row.rowheader {
    padding: 0;
    height: 6px;
  }
  .row.rowheader .cell {
    display: none;
  }
  .row .cell {
    margin-bottom: 10px;
  }
  .row .cell:before {
    margin-bottom: 3px;
    content: attr(data-title);
    min-width: 98px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: #969696;
    display: block;
  }
}

.cell {
  padding: 6px 12px;
  display: table-cell;
}
@media screen and (max-width: 580px) {
  .cell {
    padding: 2px 16px;
    display: block;
  }
}

.searchBtn{
  margin-bottom: 2em;;
}